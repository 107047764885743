import availableRoutes from "../../../routes/availableRoutes"
import { getActions as getRowActions } from "../VenuePage/VenuePage.actions"

export const getActions = (t, navigate, user) => [
    {
        title: t("common.create"),
        onExecute: () => {
            navigate(availableRoutes.venues_add.path)
        },
        condition: () => user.hasPermission("administer-venues")
    },
]

export const getTableActions = (...props) => getRowActions(...props)