import availableRoutes from "../../../routes/availableRoutes"
import { Edit, Delete } from "@mui/icons-material"
import _has from 'lodash/has'

export const getActions = (apiClient, t, itemProps, navigate, entityId = null) => {
    const { scope, icon, reloadOnSuccess, showLoading } = { ...itemProps }
    return [
        {
            id: "edit",
            title: t("common.edit"),
            scope: scope,
            icon: icon && Edit,
            reloadOnSuccess: reloadOnSuccess,
            showLoading: showLoading,
            onExecute: (venue) => {
                return Promise.resolve(venue)
                    .then((venue) => {
                        navigate(availableRoutes.venues_edit.path.replace(":entityId", entityId ?? venue.id))
                    })
            },
            condition: (venue) => _has(venue.getActions(), "edit")
        },
        {
            id: "delete",
            title: t("common.delete"),
            scope: scope,
            icon: icon && Delete,
            reloadOnSuccess: reloadOnSuccess,
            showLoading: showLoading,
            confirmDialog: {
                title: t("venues.actions.delete.modal_title"),
                content: (venue) =>
                    t("venues.actions.delete.modal_content", {
                        name: venue.name,
                    }),
            },
            onExecute: (venue, progressHandler, onSuccess, onError) => {
                return apiClient.venue.deleteVenue(venue.id)
                    .then((result) => {
                        onSuccess(
                            t("venues.actions.delete.confirm", {
                                name: venue.name,
                            })
                        )
                        navigate(availableRoutes.venues.path)
                    })
                    .catch((error) => {
                        onError(
                            t("venues.actions.delete.error", {
                                name: venue.name,
                            })
                        )

                        throw error
                    })
            },
            condition: (venue) => _has(venue.getActions(), "delete")
        }
    ]
}