import { useTranslation } from "react-i18next";
import constants from "../../../../../../components/entity/constants";
import {createField, createLinkField} from "../../../../../../components/helpers/fieldHelper";
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable";
import _isEmpty from "lodash/isEmpty";

const PickupPointsConfig = (props) => {

    const { t } = useTranslation("vbms")
    const getColumns = (includeRelativeMinutes) => {
        return [
            createLinkField(
              createField(
                "name",
                t("pickup_points.fields.name"),
                constants.STRING_TYPE
              ),
              {
                  route: "pickup_points",
                  field: "id",
              }
            ),
            ...(includeRelativeMinutes
              ? [
                  createField(
                    "pickup_config_info.relative_minutes",
                    t("pickup_points.fields.relative_minutes"),
                    constants.STRING_TYPE
                  ),
              ]
              : []),
            createField(
              "created",
              t("pickup_points.fields.created"),
              constants.DATETIME_TYPE
            ),
            createField(
              "modified",
              t("pickup_points.fields.modified"),
              constants.DATETIME_TYPE
            ),
        ];
    }

    return <>
        <h3>{t("pickup_configs.view.pickup_points")}</h3>
        <ArrayDataTable
            columns={getColumns(true)}
            data={props.data}
        />
        <h3>{t("pickup_configs.view.final_pickup_point")}</h3>
        <ArrayDataTable
            columns={getColumns(false)}
            data={[_isEmpty(props.entity.final_point) ? '' : props.entity.final_point]}
        />
    </>

}

export default PickupPointsConfig
