import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import {
    createField,
    createFormFieldGroup,
    createFormFieldset,
    createFormField, createAsyncSelectFormField, createFormFieldOption, createDependentFormField
} from "../../../components/helpers/fieldHelper"
import constants from "../../../components/entity/constants"
import AsyncEntityForm from "../../../components/form/AsyncEntityForm"
import {buildOptions, DEFAULT_LIMIT} from "../../../lib/form-options-fetchers"
import _get from "lodash/get";
import {useVolcanoApiClient} from "../../../context/VolcanoApiClientProvider"
const LodgingForm =  ({ selection, onAction }) => {

    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const { user } = useVolcanoAuth()

    const fetcher = () => Promise.resolve(selection === undefined ? {
        name: "",
    } : {
            ...selection,
            area: formatArea(selection.area_components)
        }
    )

    const formatArea = (areas) => {
        if (!areas) {
            return {}
        }
        const areaLO = areas.filter(area => area.cod === "LO");
        return areaLO.length > 0 ? areaLO[0] : {}
    }

    const onConfirm = (data) => {

        const lodging = {
            ...data
        }
        lodging["enterprise_id"] = user.corporate_account.enterprise.id

        onAction(lodging)
    }

    const formContent = [
        createFormFieldset(t("lodgings.form.fieldset_information"),
            [
                createFormFieldGroup([
                    createFormField(
                        createField(
                            "name",
                            t("lodgings.fields.name"),
                            constants.STRING_TYPE
                        ),
                        true,
                        ""
                    ),
                ]),
                createFormField(
                    createField(
                        "address",
                        t("lodgings.fields.address"),
                        constants.STRING_TYPE
                    ),
                    false,
                    ""
                ),
                createFormField(
                    createField(
                        "phone",
                        t("lodgings.fields.phone"),
                        constants.STRING_TYPE
                    ),
                    false,
                    ""
                ),
                createFormField(
                    createField(
                        "mobile",
                        t("lodgings.fields.mobile"),
                        constants.STRING_TYPE
                    ),
                    false,
                    ""
                ),
                createFormField(
                    createField(
                        "email",
                        t("lodgings.fields.email"),
                        constants.STRING_TYPE
                    ),
                    false,
                    ""
                ),
                createFormField(
                    createField(
                        "web",
                        t("lodgings.fields.web"),
                        constants.STRING_TYPE
                    ),
                    false,
                    ""
                ),
                createAsyncSelectFormField(
                    "zone.id",
                    t("lodgings.fields.zone"),
                    () => {
                        let options = [createFormFieldOption("" ,t("table.all"))]
                        const params = {
                            limit: DEFAULT_LIMIT,
                            sort: "name"
                        }
                        return apiClient.activityManager.zone.getZones(params)
                            .then((items) =>
                                options.concat(
                                    buildOptions(items.getItems(), t, "id", "name", false)
                                )
                            )
                    },
                    false
                ),
                createDependentFormField(
                    "area.id",
                    t("lodgings.fields.locality"),
                    constants.SELECT_TYPE,
                    (values) => {

                        let options = [createFormFieldOption("" ,t("table.all"))]

                        const params = {
                            zone_id:   _get(values, "zone.id"),
                            limit: DEFAULT_LIMIT,
                            code: "LO",
                            sort: "name"
                        }

                        return apiClient.activityManager.area.getAreas(params)
                            .then((items) =>
                                options.concat(
                                    buildOptions(items.getItems(), t, "id", "name", false)
                                )
                            )
                    },
                    true
                ),
                createFormField(
                    createField(
                        "coordinates.lat",
                        t("lodgings.fields.latitude"),
                        constants.STRING_TYPE
                    ),
                    true,
                    ""
                ),
                createFormField(
                    createField(
                        "coordinates.lng",
                        t("lodgings.fields.longitude"),
                        constants.STRING_TYPE
                    ),
                    true,
                    ""
                ),
            ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default LodgingForm
