import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../context/VolcanoApiClientProvider"
import { useNavigate, useParams } from "react-router-dom"
import { useVolcanoAuth } from "../../context/VolcanoAuthContext";
import { createField, createFormFieldset, createFormField, createCallbackField, createFormFieldGroup } from '../../components/helpers/fieldHelper';
import AsyncEntityFormPage from "../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import Loading from "../../components/Display/Loading";
import constants from "../../components/entity/constants"
import useSnackBars from "../../components/Snackbar/snack-bar.context";
import RulesSelector from "../../components/Volcano/RulesSelector/RulesSelector";
import { RulesConfig } from "./VenuePage/components/RulesConfig";
import availableRoutes from "../../routes/availableRoutes";

const VenueFormPage = () => {
    const navigate = useNavigate()
    const { t } = useTranslation("vbms")

    const { apiClient } = useVolcanoApiClient()
    const { entityId } = useParams()
    const { user } = useVolcanoAuth()
    const { addAlert } = useSnackBars()
    const [isReady, setIsReady] = useState(false)

    const config = RulesConfig(t)
    const [venue, setVenue] = useState({})
    const [rules, setRules] = useState([])

    const onChange = (data) => {
        setRules(data)
    }

    const fetcher = useCallback(() => {
        setIsReady(false)
        if (!entityId) {
            setIsReady(true)
            return new Promise((res) => res({}))
        }

        return apiClient.venue.getVenue(entityId).then((venue) => {
            setIsReady(true)
            setVenue(venue)
            return venue
        })
    }, [apiClient, entityId])

    const checkInvalidConfigs = (configs) => {
        return configs.some(config => !config.date_from || !config.date_to);
    }


    const onSaveHandler = (data) => {
        setIsReady(false)

        if (checkInvalidConfigs(rules)) {
            addAlert("Error: " + t("venues.constants.invalid_config_message"))
            setIsReady(true)
            return
        }

        const venueData = {
            ...data,
            config: { [config.route]: rules },
            enterprise: { id: user.corporate_account.enterprise.id },
            sites: venue.sites ? venue.sites.map((site) => ({ id: site.id })) : [{ id: user.site.id }]
        }

        if (!entityId) {
            apiClient.venue.addVenue(venueData)
                .then(() => {
                    navigate(availableRoutes.venues.path)
                })
                .catch((error) => {
                    setIsReady(true)
                    addAlert("Error: " + error.message)
                })
        } else {
            apiClient.venue.editVenue(entityId, venueData)
                .then((venue) => {
                    navigate(`${availableRoutes.venues.path}/${entityId}`)
                })
                .catch((error) => {
                    setIsReady(true)
                    addAlert("Error: " + error.message)
                })
        }
    }

    const formContent = [
        createFormFieldset(t("venues.form.fieldset_information"), [
            createFormFieldGroup([
                createFormField(
                    createField(
                        "name",
                        t("venues.fields.name"),
                        constants.STRING_TYPE
                    ),
                    true
                ),
                createFormField(
                    createField(
                        "key",
                        t("venues.fields.key"),
                        constants.STRING_TYPE
                    ),
                    true
                )

            ])
        ])
    ]

    useEffect(() => {
        venue.config && setRules(venue.config[config.route])
    }, [venue])

    return (
        <>
            <Loading open={!isReady} />
            <AsyncEntityFormPage
                fetcher={fetcher}
                title={
                    createCallbackField(
                        "name",
                        t("venues.form.add_title"),
                        constants.STRING_TYPE,
                        (entity, values) => values.name ? t("venues.form.title", { name: values.name }) : t("venues.form.add_title")
                    )
                }
                onSave={onSaveHandler}
                formContent={formContent}
            />
            <RulesSelector
                open={!isReady}
                fetcher={fetcher}
                promise={true}
                config={RulesConfig(t)}
                onChange={onChange}
                editable={true}
                container={true}
            />
        </>

    )
}

export default VenueFormPage