import { useTranslation } from "react-i18next";
import constants from "../../../../../../components/entity/constants";
import { createField } from "../../../../../../components/helpers/fieldHelper";
import ArrayDataTable from "../../../../../../components/Datatable/ArrayDataTable";

const ActivityConfigConfiguration = (props) => {

    const { t } = useTranslation("vbms")

    const getDataWithIndex = (data) => {
        return data.map((item, index) => {
            return {
                date_from: item.date_from,
                date_to: item.date_to,
                weekdays: item.schedule[0].weekdays,
                id: index + 1
            }
        })
    }

    const columns = [
        createField(
            "date_from",
            t("activity_configs.fields.date_from"),
            constants.DATE_TYPE,
            null,
            null,
            null,
            null,
            'left'
        ),
        createField(
            "date_to",
            t("activity_configs.fields.date_to"),
            constants.DATE_TYPE,
            null,
            null,
            null,
            null,
            'left'
        ),
        createField(
            "weekdays",
            t("activity_configs.fields.weekdays"),
            constants.WEEKDAYS_TYPE,
            null,
            null,
            null,
            null,
            'left'
        ),
    ]

    return (
        <ArrayDataTable
            data={props.data ? getDataWithIndex(props.data) : []}
            columns={columns}
        />
    )

}

export default ActivityConfigConfiguration
