import { createField, createFormField, createAsyncSelectFormField, createBooleanFormField, createDependentFormField } from "../../../components/helpers/fieldHelper"
import { sitesFetcher, tagGroupsFetcher } from "../../../lib/form-options-fetchers"
import { buildOptions } from "../../../lib/form-options-fetchers"
import constants from "../../../components/entity/constants"
import _get from "lodash/get"


export const getTableFilter = (apiClient, t, user, cache) => {

    return ([
        createFormField(
            createField(
                "slug",
                t("tags.filter.slug"),
                constants.STRING_TYPE
            ),
            false
        ),
        createFormField(
            createField(
                "name",
                t("tags.filter.name"),
                constants.STRING_TYPE
            ),
            false
        ),
        // Sites dependent filter of enterprise (For admin users)
        createDependentFormField(
            "site_id",
            t("tags.filter.site"),
            constants.SELECT_TYPE,
            (values) => {
                const enterpriseId = parseInt(_get(values, "enterprise_id", "-1"))
                return sitesFetcher(cache, apiClient)
                    .then((sites) => {
                        if (enterpriseId !== -1) sites = sites.filter((item) => item.enterprise.id === enterpriseId)

                        return buildOptions(sites, t)
                    })
            },
            () => user.hasPermission("access-any-enterprise")
        ),
        // Sites non-dependent filter (For sites users)
        createAsyncSelectFormField(
            "site_id",
            t("tags.filter.site"),
            (params) => sitesFetcher(cache, apiClient, params).then((sites) => buildOptions(sites, t)),
            false,
            () => !user.hasPermission("access-any-enterprise")
        ),
        createDependentFormField(
            "group_id",
            t("tags.filter.group"),
            constants.SELECT_TYPE,
            (values) => {
                const enterpriseId = parseInt(_get(values, "enterprise_id", "-1"))
                const siteId = parseInt(_get(values, "site_id", "-1"))
                return tagGroupsFetcher(cache, apiClient)
                    .then((tagGroups) => {
                        if (enterpriseId !== -1 && siteId !== -1) tagGroups = tagGroups.filter((item) => item.site.id === siteId)

                        return buildOptions(tagGroups, t)
                    })
            },
        ),
        createBooleanFormField(
            t,
            "visible",
            t("tags.filter.visible")
        ),
        createBooleanFormField(
            t,
            "protected",
            t("tags.filter.protected"),
        ),
    ])
}
