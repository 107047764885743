import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import {
    createEditableListFormField,
    createCallbackField, createFormFieldset, createFormField, createField, createRangeDatesField,
} from "../../../../components/helpers/fieldHelper"
import AsyncEntityFormPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import {useNavigate, useParams} from "react-router-dom"
import availableRoutes from "../../../../routes/availableRoutes"
import _isEmpty from "lodash/isEmpty";
import useSnackBars from "../../../../components/Snackbar/snack-bar.context";


const ActivityConfigConfigurationPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const navigate = useNavigate()
    const { entityId } = useParams()
    const { addAlert } = useSnackBars()


    const fetcher = () => {
        return apiClient.activityManager.activityConfig
            .getActivityConfig(entityId, [])
            .then((activityConfig) => {
                let configuration = []
                if (!_isEmpty(activityConfig.availability_rules)) {
                    configuration = activityConfig.availability_rules.map((rule) => {
                        return {
                            date_from: rule.date_from,
                            date_to: rule.date_to,
                            weekdays: rule.schedule[0].weekdays
                        }
                    })
                }

                return {
                    ...activityConfig,
                    configuration: configuration
                }
            })
    }

    const handleClose = () => {
        navigate(
            availableRoutes.route_view.path.replace(":entityId", entityId)
        )
    }

    const onConfirm = (values) => {
        const configuration = values.configuration.map((item) => {
            return {
                schedule: [
                    {
                        weekdays: item.weekdays
                    }
                ],
                date_from: item.date_from,
                date_to:  item.date_to
            }
        })

        const availabilityRules = {
            availability_rules: configuration
        }

        return apiClient.activityManager.activityConfig
            .updateConfiguration(entityId, availabilityRules)
            .then(() => {
                navigate(
                    availableRoutes.activity_config_view.path.replace(":entityId", entityId)
                )
            })
            .catch((error) => {
                addAlert(error.data.message, "error")
            })
    }

    const formContent = [
        createFormFieldset(t("activity_configs.form.fieldset_configurations"), [
            createEditableListFormField(
                "configuration",
                null,
                "",
                t("activity_configs.actions.set_configuration.modal_title"),
                null,
                false,
                true,
                true,
                null,
                [
                    createFormField(
                        createRangeDatesField(
                            "date_from",
                            t("activity_configs.fields.date_from"),
                            constants.RANGE_DATES_TYPE,
                            "date_to",
                            true
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createRangeDatesField(
                            "date_to",
                            t("activity_configs.fields.date_to"),
                            constants.RANGE_DATES_TYPE,
                            "date_from",
                            false
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createField(
                            "weekdays",
                            t("activity_configs.fields.weekdays"),
                            constants.WEEKDAYS_TYPE
                        ),
                        true,
                        ""
                    ),
                ]
            )
        ])
    ];

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("activity_configs.fields.name"),
                constants.STRING_TYPE,
                (value) => {
                    return t("activity_configs.view.title", { name: value })
                }
            )}
            variant="extended"
            onSave={onConfirm}
            onClose={handleClose}
            formContent={formContent}
        />
    );
};

export default ActivityConfigConfigurationPage
