import { createTabBarItem } from "../../../../components/helpers/fieldHelper";
import RouteConfiguration from "./components/tabs/RouteConfiguration";
import RouteProducts from "./components/tabs/RouteProducts";
import RoutePickupConfigs from "./components/tabs/RoutePickupConfigs";


export const getTabNavigationItems = (user, apiClient, t) => [
    createTabBarItem(
        t("routes.view.navbar.configuration"),
        ({ entity }) => (
            <RouteConfiguration
                data={entity.configuration}
                entity={entity}
            />
        ),
        null,
        (entity) => entity.configuration
    ),
    createTabBarItem(
        t("routes.view.navbar.products"),
        ({ entity }) => (
            <RouteProducts
                data={entity.products}
                entity={entity}
            />
        ),
        null,
        (entity) => entity.products
    ),
    createTabBarItem(
        t("routes.view.navbar.pickup_configs"),
        ({ entity, data }) => (
            <RoutePickupConfigs
                data={entity.pickup_configs}
                entity={entity}
            />
        ),
        null,
        (entity) => entity.pickup_configs
    )

];
