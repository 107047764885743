import { createFormFieldOption, createPaymentMethodMapping } from '../components/helpers/fieldHelper';
import _get from 'lodash/get';

export const DEFAULT_LIMIT = 2000

export const cachedFetcher = async (cache, cacheKey, fetcher) => {
    return cache.get(cacheKey, [], () => fetcher.then((result) => result.getItems()))
}

export const buildOptions = (items, t, idField = "id", labelField = "name", allOption = true, sortByLabel = true, withItem = false) => {
    let options = []

    if (allOption) {
        options.push(createFormFieldOption("", t("table.all")))
    }

    if (sortByLabel) {
        items
            .sort((a, b) => _get(a, labelField) < _get(b, labelField) ? -1 : 1)
            .forEach(item => options.push(createFormFieldOption(String(item[idField]), _get(item, labelField))))
    } else {
        items
            .forEach(item => {
                let option = createFormFieldOption(String(item[idField]), _get(item, labelField))
                if (withItem) {
                    option.entity = item
                }        
                options.push(option)
            })
    }

    return options.filter(option => option.value !== null)
}

export const optionsFetcher = async (fetcher, t, allOption = true) => {
    return buildOptions(await fetcher, t, "id", "name", allOption)
}

export const apiOptionsFetcher = async (fetcher, t, idField = "id", labelField = "name", allOption = true, sortByLabel = true, getItems = true, withItem = false) => {
    let result = await fetcher
    result = getItems ? result.getItems() : result
    return buildOptions(result, t, idField, labelField, allOption, sortByLabel, withItem)
}

export const cachedOptionsFetcher = async (cache, cacheKey, fetcher, t, allOption = true) => {
    const result = await cachedFetcher(cache, cacheKey, fetcher)
    return buildOptions(result, t, "id", "name", allOption)
}

export const contactSchemaKeyItemsFetcher = (schemaKey, cache, apiClient, t, allOption = true) => {
    return crmEntitySchemaKeyItemsFetcher("contact_schema", schemaKey, cache, apiClient, t, allOption)
}

export const crmSchemaKeyItemsFetcher = (schemaKey, cache, apiClient, t, allOption = true, emptyOption = false, translateOptions = false) => {
    const result = crmEntitySchemaKeyItemsFetcher("crm_intermediaries_schema", schemaKey, cache, apiClient, t, allOption, translateOptions).then((res) => {
        let options = []

        if (allOption) {
            options.push(createFormFieldOption("", t("table.all")))
        } else if (emptyOption) {
            options.push(createFormFieldOption("", "-"))
        }
        return options.concat(res)

    })

    return result
}

export const crmEntitySchemaKeyItemsFetcher = (cacheName, schemaKey, cache, apiClient, t, allOption = true, translateOptions = false) => {
    return cache.get(cacheName, [], () => apiClient.schema.getEntitySchema(cacheName)).then((schema) => {
        let options = []

        if (schema[schemaKey] && schema[schemaKey].options) {
            Object.keys(schema[schemaKey].options).forEach((key) => {
                if (!translateOptions || !translateOptions[key]) {
                    options.push(createFormFieldOption(key, schema[schemaKey].options[key]))
                } else {
                    options.push({
                        value: key, 
                        label: translateOptions[key]
                    })
                }
            })
        }
        return options
    })
}

export const enterprisesFetcher = (cache, apiClient, t, allOption = true) => {
    return cachedOptionsFetcher(cache, "enterprises", apiClient.enterprise.getEnterprises(), t, allOption)
}

export const paymentModesFetcher = (cache, apiClient, t, allOption = true) => {
    return cache.get("payment_methods", [], () => apiClient.paymentGateway.getPaymentGateways().then((result) => {
        const paymentModes = []

        result.getItems().forEach((item) => {
            if (paymentModes.findIndex((pm) => pm.id === item.payment_method_id) < 0) {
                paymentModes.push({
                    id: item.payment_method_id,
                    name: _get(createPaymentMethodMapping(t), item.payment_method)
                })
            }
        })

        return paymentModes
    })).then((items) => {
        return buildOptions(items, t, "id", "name", allOption)
    })
}

export const sitesFetcher = (cache, apiClient, params = {}) => {
    params.view_mode = "summary"
    return cachedFetcher(cache, "sites", apiClient.content.site.getSites(params))
}

export const tagGroupsFetcher = (cache, apiClient, params = {}) => {
    params.view_mode = "summary"
    return cachedFetcher(cache, "tag_groups", apiClient.content.tag.getTagGroups(params))
}

export const experiencesFetcher = (apiClient, t, params = {}, allOption = true) => {
    params = params || {}
    params.limit = params.limit || DEFAULT_LIMIT

    return apiOptionsFetcher(apiClient.experience.getExperiences(params), t, "id", "name", allOption)
}

export const intermediariesFetcher = (apiClient, t, params = {}, allOption = true) => {
    params = params || {}
    params.limit = params.limit || DEFAULT_LIMIT
    return apiOptionsFetcher(apiClient.intermediary.getIntermediaries(params), t, "id", "name", allOption)
}

export const collaboratorsFetcher = (apiClient, t, params = {}, allOption = true) => {
    params = params || {}
    params.limit = params.limit || DEFAULT_LIMIT
    return apiOptionsFetcher(apiClient.intermediary.getIntermediaries(params), t, "collaborator_id", "name", allOption)
}

export const rolesFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
    params = params || {}
    params.limit = params.limit || DEFAULT_LIMIT
    return cachedOptionsFetcher(cache, "roles", apiClient.role.getRoles(params), t, allOption)
}

export const notificationSchemaKeyItemsFetcher = (schemaKey, cache, apiClient, t, map, allOption = true, emptyOption = false) => {
    const result = notificationsEntitySchemaKeyItemsFetcher("notifications_schema", schemaKey, cache, apiClient, t, map, allOption).then((res) => {
        let options = []

        if (allOption) {
            options.push(createFormFieldOption("", t("table.all")))
        } else if (emptyOption) {
            options.push(createFormFieldOption("", "-"))
        }
        return options.concat(res)

    })

    return result
}

export const notificationsEntitySchemaKeyItemsFetcher = (cacheName, schemaKey, cache, apiClient, t, map, allOption = true) => {
    return cache.get(cacheName, [], () => apiClient.schema.getEntitySchema(cacheName)).then((schema) => {
        let options = []

        Object.keys(schema[schemaKey].options).forEach((key) => {
            options.push({
                value: key, 
                label: _get(map(t), schema[schemaKey].options[key])
            })
        })
        return options
    })
}


