import { createField, createAsyncSelectFormField, createDependentFormField} from "../../../components/helpers/fieldHelper"
import constants from "../../../components/entity/constants"
import { buildOptions } from "../../../lib/form-options-fetchers"
import { sitesFetcher } from "../../../lib/form-options-fetchers"
import _get from "lodash/get"
import userPermissions from "../../../lib/user-permissions"

export const getTableFilter = (apiClient, t, user, cache) => {

    return ([
        createField(
            "name",
            t("venues.filter.name"),
            constants.STRING_TYPE
        ),
        createField(
            "key",
            t("venues.filter.key"),
            constants.STRING_TYPE
        ),
        createDependentFormField(
            "site_id",
            t("venues.filter.sites"),
            constants.SELECT_TYPE,
            () => {
                const enterpriseId = parseInt(_get(user, "corporate_account.enterprise.id", "-1"))
                return sitesFetcher(cache, apiClient)
                    .then((sites) => {
                        if (enterpriseId !== -1) sites = sites.filter((item) => item.enterprise.id === enterpriseId)

                        return buildOptions(sites, t)
                    })
            }
        ),
        // Sites non-dependent filter (For sites users)
        createAsyncSelectFormField(
            "site_id",
            t("venues.filter.sites"),
            (params) => sitesFetcher(cache, apiClient, params).then((sites) => buildOptions(sites, t)),
            false,
            () => !user.hasPermission(userPermissions.PERM_ACCESS_ANY_ENTERPRISE)
        ),
    ])
}
