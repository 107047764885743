import { Field } from "formik"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useTranslation } from "react-i18next"
import { parseISO, isValid } from "date-fns"
import _get from "lodash/get"

const RangeDatesFormField = ({ field, form }) => {
    const { i18n } = useTranslation("vbms")
    const language = i18n.language

    const handleChange = (value) => {
        form.setFieldValue(field.name, value ? value.toISOString().split("T")[0] : null)
    }

    const getMinDate = () => {
        if (!field.isMinField) {
            const fieldParts = field.id.split('.')
            fieldParts.pop()
            const parentPath = fieldParts.join('.')

            const referenceFieldId = `${parentPath}.${field.referenceField}`
            const refValue = _get(form.values, referenceFieldId)

            if (refValue) {
                let date = parseISO(refValue)
                if (!isValid(date)) {
                    date = new Date(refValue)
                }
                if (isValid(date)) {
                    return date
                }
            }
        }
        return null
    }

    const getMaxDate = () => {
        if (field.isMinField) {

            const fieldParts = field.id.split('.')
            fieldParts.pop()
            const parentPath = fieldParts.join('.')

            const referenceFieldId = `${parentPath}.${field.referenceField}`
            const refValue = _get(form.values, referenceFieldId)

            if (refValue) {
                let date = parseISO(refValue)
                if (!isValid(date)) {
                    date = new Date(refValue)
                }
                if (isValid(date)) {
                    return date
                }
            }
        }
        return null
    }


    function getDateFormatString(locale) {
        const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date())

        return formatObj
            .map((obj) => {
                switch (obj.type) {
                    case "day":
                        return "dd"
                    case "month":
                        return "MM"
                    case "year":
                        return "yyyy"
                    default:
                        return obj.value
                }
            })
            .join("")
    }


    let dateValue = field.value ? parseISO(field.value) : null
    if (dateValue && !isValid(dateValue)) {
        dateValue = new Date(field.value)
    }
    if (dateValue && !isValid(dateValue)) {
        dateValue = null
    }

    return (
        <DatePicker
            format={getDateFormatString(language)}
            label={field.label}
            value={dateValue}
            onChange={handleChange}
            minDate={getMinDate()}
            maxDate={getMaxDate()}
            slotProps={{
                textField: {
                    size: 'small',
                    InputLabelProps: { shrink: true },
                    fullWidth: true
                }
            }}
        />
    )
}

const FormikRangeDatesFormField = (props) => (
    <Field name={props.field.name}>
        {({ field, form }) => (
            <RangeDatesFormField field={{ ...field, ...props.field }} form={form} />
        )}
    </Field>
)

export default FormikRangeDatesFormField
