import constants from "../../../../components/entity/constants";
import {
    createAsyncSelectFormField,
    createDependentFormField,
    createField, createFormFieldOption
} from "../../../../components/helpers/fieldHelper";
import { buildOptions, DEFAULT_LIMIT } from "../../../../lib/form-options-fetchers";
import { zonesFetcher } from "../../libs/form-options-fetchers";
import _get from "lodash/get";

export const getTableFilter = (t, apiClient, user, cache) => ([
    createField(
        "name",
        t("lodgings.fields.name"),
        constants.TEXT_TYPE
    ),
    createAsyncSelectFormField(
        "zone_id",
        t("lodgings.fields.zone"),
        () => zonesFetcher(cache, apiClient, t, {
            limit: DEFAULT_LIMIT
        },true),
        false
    ),
    createDependentFormField(
        "area_id",
        t("lodgings.fields.locality"),
        constants.SELECT_TYPE,
        (values) => {

            let options = [createFormFieldOption("" ,t("table.all"))]

            const params = {
                zone_id:  _get(values, "zone_id"),
                code: "LO",
                limit: DEFAULT_LIMIT,
                sort: "name"
            }

            return apiClient.activityManager.area.getAreas(params)
                .then((items) =>
                    options.concat(
                        buildOptions(items.getItems(), t, "id", "name", false)
                    )
                )
        },
    ),
])
