import { useEffect, useState } from 'react'
import { Stack, useMediaQuery, useTheme } from '@mui/material'
import ChipList from '../Display/ChipList'
import { useTranslation } from 'react-i18next'
import constants from '../entity/constants'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import _has from 'lodash/has'

const processSummaryItems = (summaryItems, maxItems) => {
    if (summaryItems.length > maxItems + 1) {
        const items = summaryItems.slice(0, maxItems)
        const labelStart = maxItems === 0 ? "... " : "... más ";

        items.push({
            id: "",
            label: labelStart + (summaryItems.length - maxItems) + " filtros",
            hideDelete: true
        })

        return items
    }

    return summaryItems
}

const processSummaryItemLabel = (locale, value, type, options = null) => {
    if (type === constants.DATE_TYPE) {
        return Intl.DateTimeFormat(locale, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }).format(value)
    }

    if (options) {
        const selectedOption = options.filter((option) => `${option.value}` === `${value}`)

        return selectedOption.length > 0 ? selectedOption[0].label : ""
    }

    if (_isObject(value)) {
        return value.label
    }

    return value
}

const processSummaryItem = (filterConfig, filter, key, i18n) => {
    const item = filterConfig.find((item) => item.id === key)

    if (!item) {
        return ""
    }

    if (item.multiple || _isArray(filter[key])) {
        const values = filter[key]

        if (values.length === 0) {
            return ""
        }

        const label = values.map((value) => processSummaryItemLabel(i18n.language, value, item.type, item?.options)).join(", ")

        return {
            id: key,
            label: item.label + ": " + label,
            hideDelete: item.required
        }
    }

    return {
        id: key,
        label: item.label + ": " + processSummaryItemLabel(i18n.language, filter[key], item.type, item?.options),
        hideDelete: item.required
    }
}

const FilterBarSummary = ({ filterConfig, filter, onFilterItemDelete }) => {
    const { i18n } = useTranslation("vbms")
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
    const maxItems = matchDownMd ? 0 : 4
    const [ready, setReady] = useState(false)

    useEffect(() => {
        setReady(false)

        // check if there are selected values with async fetchers
        if (filter) {
            const optionsFetchers = Object.keys(filter)
                .filter((key) =>
                    filter[key] !== null &&
                    filter[key] !== "" &&
                    filterConfig.some(item =>
                        item.id === key &&
                        (item.type === constants.ASYNC_SELECT_TYPE || 
                            item.type === constants.DEPENDENT_TYPE) && // only process async fields
                        !_has(item, "options") // if for any reason the options of the field has been defined then do not process the fetcher
                    )
                )
                .map((key) => ({
                    key: key,
                    fetcher: filterConfig[filterConfig.findIndex(item => item.id === key)].optionsFetcher()
                }))

            if (Object.keys(optionsFetchers).length === 0) {
                setReady(true)
            }

            // wait until all async field options has been loaded in order to process the selected fitlers
            Promise.all(optionsFetchers.map(item => item.fetcher))
                .then(values => {
                    values.forEach((value, index) => {
                        const key = optionsFetchers[index].key
                        filterConfig[filterConfig.findIndex(item => item.id === key)].options = value
                    })

                    setReady(true)
                })
        }
    }, [filterConfig, filter, setReady])

    if (!ready) {
        return null
    }

    const summaryItems = filter ? Object.keys(filter)
        .filter((key) => filter[key] !== null && filter[key] !== "" && key !== "agg_fields")
        .map((key) => processSummaryItem(filterConfig, filter, key, i18n))
        .filter((item) => item !== "") : []

    return (
        <Stack sx={{ marginTop: 1, marginButton: 0 }}>
            <ChipList selection={processSummaryItems(summaryItems, maxItems)} maxChars={30} onValueDeleted={onFilterItemDelete} />
        </Stack>
    )
}

export default FilterBarSummary