import { useTranslation } from "react-i18next"
import { useVolcanoApiClient } from "../../../context/VolcanoApiClientProvider"
import CollectionDataTable from "../../Datatable/CollectionDataTable"
import { createField, createLinkField } from "../../helpers/fieldHelper"
import constants from "../../entity/constants"

const ProductsTable = ({ defaultParams }) => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")

    const columns = [
        createLinkField(
            createField(
                "name",
                t("experiences.fields.name"),
                constants.TEXT_TYPE
            ),
            {
                route: "products",
                field: "id",
            }
        )
    ]

    const productsFetcher = (params) => {
        params = {
            ...defaultParams
            , ...params
        }
        return apiClient.content.experience.getProducts(params)
    }

    return (
        <CollectionDataTable
            fetcher={productsFetcher}
            columns={columns}
        />
    )
}

export default ProductsTable