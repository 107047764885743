import { CollectionProvider } from "../entity/context/collection/collection.context"
import { FilterProvider, useFilterContext } from "../entity/context/filter/filter.context"
import DataTable from "./DataTable"

const FilteredCollectionDataTable = (props) => {
    return <CollectionProvider
        fetcher={props.fetcher}
        rowsPerPage={props.rowsPerPage ?? 20}
    >
        <DataTable
            selectionMode={props.selectionMode}
            selected={props.selected ?? []}
            groupBy={props?.groupBy ?? null}
            order={props.order}
            orderBy={props.orderBy}
            columns={props.columns}
            highlight={props.highlight}
            actions={props.actions}
            onExport={props.onExport}
            onSelectionChange={props.onSelectionChange}
            hideShare={props.hideShare}
            hideFilter={props.hideFilter}
        />
    </CollectionProvider >
}

const NonFilteredCollectionDataTable = (props) =>
    <FilterProvider filterConfig={props?.filterConfig} >
        <FilteredCollectionDataTable {...props} />
    </FilterProvider>

const CollectionDataTable = (props) => {
    const context = useFilterContext()

    if (!context || props?.filterConfig) {
        return <NonFilteredCollectionDataTable {...props} />
    }

    return <FilteredCollectionDataTable {...props} hideFilter={true} />
}

export default CollectionDataTable