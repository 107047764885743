import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import {
    createEditableListFormField,
    createCallbackField, createFormFieldset, createFormField, createField, createRangeDatesField,
} from "../../../../components/helpers/fieldHelper"
import AsyncEntityFormPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import {useNavigate, useParams} from "react-router-dom"
import availableRoutes from "../../../../routes/availableRoutes"


const RouteConfigurationPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const navigate = useNavigate()
    const { entityId } = useParams()


    const fetcher = () => {
        return apiClient.activityManager.route
            .getRoute(entityId, [])
            .then((route) => {
                return {
                    ...route
                }
            })
    }

    const handleClose = () => {
        navigate(
            availableRoutes.route_view.path.replace(":entityId", entityId)
        )
    }

    const onConfirm = (values) => {
        const configuration = values.configuration.map((item) => {
            const startTimeSplit = item.config.start_time.split(":")
            if (startTimeSplit.length === 3) {
                startTimeSplit.pop()
            }
            const startTime = startTimeSplit.join(":")

            return {
                config: {
                    weekdays: item.config.weekdays,
                    start_time: startTime,
                },
                date_from: item.date_from,
                date_to:  item.date_to,
            }
        })

        const routeUpdate = {
            configuration: configuration,
        }

        return apiClient.activityManager.route
            .updateConfiguration(entityId, routeUpdate)
            .then(() => {
                navigate(
                    availableRoutes.route_view.path.replace(":entityId", entityId)
                )
            })
    }

    const formContent = [
        createFormFieldset(t("routes.form.fieldset_configurations"), [
            createEditableListFormField(
                "configuration",
                null,
                "",
                t("routes.actions.set_pickup_points.modal_title"),
                null,
                false,
                true,
                true,
                null,
                [
                    createFormField(
                        createRangeDatesField(
                            "date_from",
                            t("routes.fields.date_from"),
                            constants.RANGE_DATES_TYPE,
                            "date_to",
                            true
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createRangeDatesField(
                            "date_to",
                            t("routes.fields.date_to"),
                            constants.RANGE_DATES_TYPE,
                            "date_from",
                            false
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createField(
                            "config.start_time",
                            t("routes.fields.start_time"),
                            constants.TIME_TYPE
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createField(
                            "config.weekdays",
                            t("routes.fields.weekdays"),
                            constants.WEEKDAYS_TYPE
                        ),
                        true,
                        ""
                    ),
                ]
            ),
        ])
    ];

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("routes.fields.name"),
                constants.STRING_TYPE,
                (value) => {
                    return t("routes.view.title", { name: value })
                }
            )}
            variant="extended"
            onSave={onConfirm}
            onClose={handleClose}
            formContent={formContent}
        />
    );
};

export default RouteConfigurationPage
