import userPermissions from "../../../lib/user-permissions";
import ZonesCollectionPage from "../Zones/ZonesCollection/ZonesCollection";
import ZonePage from "../Zones/ZonePage/ZonePage";
import PickupPointsCollectionPage from "../PickupPoints/PickupPointsCollection/PickupPointsCollection";
import PickupPointPage from "../PickupPoints/PickupPointPage/PickupPointPage";
import LodgingsCollectionPage from "../Lodgings/LodgingsCollection/LodgingsCollection";
import LodgingPage from "../Lodgings/LodgingPage/LodgingPage";
import PickupConfigsCollection from "../PickupConfigs/PickupConfigsCollection/PickupConfigsCollection";
import PickupConfigPage from "../PickupConfigs/PickupConfigPage/PickupConfigPage";
import PickupConfigPickupPointsPage from "../PickupConfigs/PickupConfigPickupPointsPage/PickupConfigPickupPointsPage";
import RoutesCollectionPage from "../Routes/RoutesCollection/RoutesCollection";
import RoutePage from "../Routes/RoutePage/RoutePage";
import RouteConfigurationPage from "../Routes/RouteConfigurationPage/RouteConfigurationPage";
import ActivityConfigPage from "../ActivityConfigs/ActivityConfigPage/ActivityConfigPage";
import ActivityConfigsCollectionPage from "../ActivityConfigs/ActivityConfigsCollection/ActivityConfigsCollection";
import ActivityConfigProductsPage from "../ActivityConfigs/ActivityConfigProductsPage/ActivityConfigProductsPage";
import ActivityConfigConfigurationPage
    from "../ActivityConfigs/ActivityConfigConfigurationPage/ActivityConfigConfigurationPage";


const activitiesRoutes = {
    zones: {
        path: "/activity-manager/config/zones",
        element: ZonesCollectionPage,
        permissions: [
            userPermissions.PERM_ZONES_VIEW,
            userPermissions.PERM_ZONES_VIEW_OWN
        ]
    },
    zones_view: {
        path: "/activity-manager/config/zones/:entityId",
        element: ZonePage,
        permissions: [
            userPermissions.PERM_ZONES_VIEW,
            userPermissions.PERM_ZONES_VIEW_OWN
        ]
    },
    pickup_points: {
        path: "/activity-manager/config/pickup-points",
        element: PickupPointsCollectionPage,
        permissions: [
            userPermissions.PERM_PICKUP_POINTS_VIEW,
            userPermissions.PERM_PICKUP_POINTS_VIEW_OWN
        ]
    },
    pickup_points_view: {
        path: "/activity-manager/config/pickup-points/:entityId",
        element: PickupPointPage,
        permissions: [
            userPermissions.PERM_PICKUP_POINTS_VIEW,
            userPermissions.PERM_PICKUP_POINTS_VIEW_OWN
        ]
    },
    lodgings: {
        path: "/activity-manager/config/lodgings",
        element: LodgingsCollectionPage,
        permissions: [
            userPermissions.PERM_LODGINGS_VIEW,
            userPermissions.PERM_LODGINGS_VIEW_OWN
        ]
    },
    lodgings_view: {
        path: "/activity-manager/config/lodgings/:entityId",
        element: LodgingPage,
        permissions: [
            userPermissions.PERM_LODGINGS_VIEW,
            userPermissions.PERM_LODGINGS_VIEW_OWN
        ]
    },
    pickup_configs: {
        path: "/activity-manager/config/pickup-configs",
        element: PickupConfigsCollection,
        permissions: [
            userPermissions.PERM_PICKUP_CONFIGS_VIEW,
            userPermissions.PERM_PICKUP_CONFIGS_VIEW_OWN
        ]
    },
    pickup_configs_view: {
        path: "/activity-manager/config/pickup-configs/:entityId",
        element: PickupConfigPage,
        permissions: [
            userPermissions.PERM_PICKUP_CONFIGS_VIEW,
            userPermissions.PERM_PICKUP_CONFIGS_VIEW_OWN
        ]
    },
    pickup_config_pickup_points: {
        path: "/activity-manager/config/pickup-configs/:pickupConfigId/pickup-points",
        element: PickupConfigPickupPointsPage,
        permissions: [
            userPermissions.PERM_PICKUP_CONFIGS_VIEW,
            userPermissions.PERM_PICKUP_CONFIGS_VIEW_OWN
        ]
    },
    routes: {
        path: "/activity-manager/routes",
        element: RoutesCollectionPage,
        permissions: [
            userPermissions.PERM_ROUTES_VIEW,
            userPermissions.PERM_ROUTES_VIEW_OWN
        ]
    },
    route_view: {
        path: "/activity-manager/routes/:entityId",
        element: RoutePage,
        permissions: [
            userPermissions.PERM_ROUTES_VIEW,
            userPermissions.PERM_ROUTES_VIEW_OWN
        ]
    },
    route_configuration: {
        path: "/activity-manager/routes/:entityId/configuration",
        element: RouteConfigurationPage,
        permissions: [
            userPermissions.PERM_ROUTES_VIEW,
            userPermissions.PERM_ROUTES_VIEW_OWN
        ]
    },
    activity_configs: {
        path: "/activity-manager/config/activity-configs",
        element: ActivityConfigsCollectionPage,
        permissions: [
            userPermissions.PERM_ACTIVITY_CONFIGS_VIEW,
            userPermissions.PERM_ACTIVITY_CONFIGS_VIEW_OWN
        ]
    },
    activity_config_view: {
        path: "/activity-manager/config/activity-configs/:entityId",
        element: ActivityConfigPage,
        permissions: [
            userPermissions.PERM_ACTIVITY_CONFIGS_VIEW,
            userPermissions.PERM_ACTIVITY_CONFIGS_VIEW_OWN
        ]
    },
    activity_config_products: {
        path: "/activity-manager/config/activity-configs/:entityId/products",
        element: ActivityConfigProductsPage,
        permissions: [
            userPermissions.PERM_ACTIVITY_CONFIGS_VIEW,
            userPermissions.PERM_ACTIVITY_CONFIGS_VIEW_OWN
        ]
    },
    activity_config_configuration: {
        path: "/activity-manager/config/activity-configs/:entityId/configuration",
        element: ActivityConfigConfigurationPage,
        permissions: [
            userPermissions.PERM_ACTIVITY_CONFIGS_VIEW,
            userPermissions.PERM_ACTIVITY_CONFIGS_VIEW_OWN
        ]
    }
}

export default activitiesRoutes
