import {cachedOptionsFetcher, DEFAULT_LIMIT} from "../../../lib/form-options-fetchers"

export const areasFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "areas", apiClient.activityManager.area.getAreas(params), t, allOption)
}

export const zonesFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "zones", apiClient.activityManager.zone.getZones(params), t, allOption)
}

export  const lodgingsFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "lodgings", apiClient.activityManager.lodging.getLodgings(params), t, allOption)
}

export  const pickupPointsFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "pickup_points", apiClient.activityManager.pickupPoint.getPickupPoints(params), t, allOption)
}

export const pickupConfigsFetcher = (cache, apiClient, t, params = {}, allOption = true) => {
  params = params || {}
  params.limit = params.limit || DEFAULT_LIMIT
  return cachedOptionsFetcher(cache, "pickup_configs", apiClient.activityManager.pickupConfig.getPickupConfigs(params), t, allOption)
}

