import { useTranslation } from "react-i18next"
import { useVolcanoAuth } from "../../../context/VolcanoAuthContext"
import {
    createField,
    createFormFieldGroup,
    createFormFieldset,
    createFormField,
} from "../../../components/helpers/fieldHelper";
import constants from "../../../components/entity/constants"
import AsyncEntityForm from "../../../components/form/AsyncEntityForm"
const PickupConfigsForm =  ({ selection, onAction }) => {

    const { t } = useTranslation("vbms")
    const { user } = useVolcanoAuth()

    const fetcher = () => Promise.resolve(selection === undefined ? {
        name: "",
        description: ""
    } : {
            ...selection
        }
    )

    const onConfirm = (data) => {

        const pickupConfig = {
            ...data
        }
        pickupConfig["enterprise_id"] = user.corporate_account.enterprise.id

        onAction(pickupConfig)
    }

    const formContent = [
        createFormFieldset(t("pickup_configs.form.fieldset_information"),
            [
                createFormFieldGroup([
                    createFormField(
                        createField(
                            "name",
                            t("pickup_configs.fields.name"),
                            constants.STRING_TYPE
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createField(
                            "description",
                            t("pickup_configs.fields.description"),
                            constants.STRING_TYPE
                        ),
                        false,
                        ""
                    ),
                ])
            ])
    ]

    return (
        <AsyncEntityForm
            fetcher={fetcher}
            onSave={onConfirm}
            formContent={formContent}
        />
    )
}

export default PickupConfigsForm
