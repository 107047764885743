import availableRoutes from "../../../../routes/availableRoutes";
import _has from "lodash/has";
import RouteForm from "../RouteForm";
import RouteProductsForm from "../RouteProductsForm";
import RoutePickupConfigsForm from "../RoutePickupConfigsForm";


export const getActions = (apiClient, t, user, navigate, entityId) => {


    return [
        {
            id: "edit",
            title: t("common.edit"),
            reloadOnSuccess: true,
            showLoading: true,
            withDialog: {
                title: t("routes.actions.edit.modal_title"),
                content: RouteForm
            },
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.route.editRoute(entityId, item)
                    .then((result) => {
                        onSuccess(
                            t("routes.actions.edit.confirm", {
                                name: result.name,
                            })
                        )

                        return { ...result, id: entityId }
                    })
                    .catch((error) => {
                        onError(
                            t("routes.actions.edit.error", {
                                name: item.name,
                            })
                        )
                    })
            },
            condition: (item) => _has(item.getActions(), "edit")
        },
        {
            id: "route_configuration",
            title: t("routes.actions.configuration.button"),
            onExecute: (item) => {
                navigate(availableRoutes.route_configuration.path.replace(":entityId", item.id))
            },
            condition: (item) => _has(item.getActions(), "update_configuration")
        },
        {
            id: "route_products",
            title: t("routes.actions.products.button"),
            scope: "products",
            icon: null,
            showLoading: true,
            withDialog: {
                title: t("routes.actions.products.modal_title"),
                content: RouteProductsForm
            },
            reloadOnSuccess: true,
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.route.updateProducts(entityId, item)
                    .then((result) => {
                        onSuccess(
                            t("routes.actions.products.confirm", {
                                name: result.name,
                            })
                        )

                        return { ...result, id: entityId }
                    })
                    .catch((error) => {
                        onError(
                            t("routes.actions.products.error", {
                                name: item.name,
                            })
                        )
                    })
            },
        },
        {
            id: "route_pickup_configs",
            title: t("routes.actions.pickup_configs.button"),
            scope: "pickup_configs",
            icon: null,
            showLoading: true,
            withDialog: {
                title: t("routes.actions.pickup_configs.modal_title"),
                content: RoutePickupConfigsForm
            },
            reloadOnSuccess: true,
            onExecute: (data, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.route.updatePickupConfigs(entityId, data)
                    .then((result) => {
                        onSuccess(
                            t("routes.actions.pickup_configs.confirm", {
                                name: result.name,
                            })
                        )

                        return { ...result, id: entityId }
                    })
                    .catch((error) => {
                        onError(
                            t("routes.actions.pickup_configs.error", {
                                name: data.name,
                            })
                        )
                    })
            },
        },
        {
            id: "delete",
            title: t("common.delete"),
            reloadOnSuccess: true,
            showLoading: true,
            confirmDialog: {
                title: t("routes.actions.delete.modal_title"),
                content: (item) =>
                    t("routes.actions.delete.modal_content", {
                        name: item.name,
                    }),
            },
            onExecute: (item, progressHandler, onSuccess, onError) => {
                return apiClient.activityManager.route.deleteRoute(item.id)
                    .then(() => {
                        onSuccess(
                            t("routes.actions.delete.confirm", {
                                name: item.name,
                            })
                        )
                        navigate(availableRoutes.routes.path)
                    })
                    .catch((error) => {
                        onError(
                            t("routes.actions.delete.error", {
                                name: item.name,
                            })
                        )
                    })
            },
            condition: (item) => _has(item.getActions(), "delete")
        },
    ]
}
