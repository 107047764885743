import { useTranslation } from "react-i18next";
import { Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { parseISO } from "date-fns";
import _isEmpty from "lodash/isEmpty"
import SimpleLoading from "../../../../../components/Display/SimpleLoading";
import { useNavigate, useParams } from "react-router-dom";
import useSnackBars from "../../../../../components/Snackbar/snack-bar.context";
import _set from "lodash/set"
import availableRoutes from "../../../../../routes/availableRoutes";
import Title from "../../../../../components/Display/Title";
import { ConfigureNotificationSessions, ConfigureSessionsProduct } from "../tabs/NotificationRecipients/NotificationRecipients.functions";
import { useVolcanoApiClient } from "../../../../../context/VolcanoApiClientProvider";
import theme from "../../../../../config/theme";
import EntityActionsGroup from "../../../../../components/action/EntityActionsGroup";


const NotificationSessionsConfiguration = ({ props }) => {
    const { t } = useTranslation("vbms")
    const { apiClient } = useVolcanoApiClient()
    const navigate = useNavigate() 
    const { addAlert } = useSnackBars()

    const { entityId } = useParams()
    const [products, setProducts] = useState([])
    const [sendDate, setSendDate] = useState(null)
    const [subtitle, setSubtitle] = useState("")

    const [productsAllSessions, setProductsAllSessions] = useState([])
    const [productsSelectedSessions, setProductsSelectedSessions] = useState([])
    const [allSessions, setAllSessions] = useState([])
    const [allSelectedSessions, setAllSelectedSessions] = useState([])

    const [notificationConfigureKey, setNotificationConfigureKey]  = useState('nc_0')
    const [notificationAllConfigureKey, setNotificationAllConfigureKey]  = useState('nc_all_0')

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        apiClient.notifications.getNotification(entityId).then((result) => {
            setProducts(result.products)
            setSendDate(parseISO(result.date, "yyyy-MM-dd"))
            setSubtitle(result.name)
        })
    }, [apiClient, entityId])

    useEffect(()=> {
        async function fetchProductSessions(products, sendDate) {
            const result = [];
            const selected = [];
            const allS = [];
          
            setLoading(true)
            await Promise.all(products.map(async (product) => {
                try {
                    const res = await apiClient.catalog.product.getProductAvailability(product.id, sendDate, false);
                    let sessions = []
                    let objectSessions = []
                    if (res && res[0]) {
                        objectSessions = res[0].sessions;
                    } 

                    if (objectSessions.length === 0) {
                        objectSessions.push({session: 'day_wide'});
                    }

                    objectSessions.forEach((session) => {
                        sessions.push(session.session.includes(":") ? session.session.substring(0, session.session.length-3) : session.session)
                    })

                    result.push({
                        product: product,
                        allsessions: sessions
                    });

                    let selectedSessions = []
                    if (product.sessions.length > 0) {
                        if (product.sessions.length === 1 && product.sessions[0] === '00:00') {
                            selectedSessions = ['day_wide']
                        } else {
                            selectedSessions = product.sessions
                        }
                    }

                    selected.push({
                        product: product,
                        sessions: selectedSessions
                    });

                    allS.push(sessions)

                } catch (e) {
                    setLoading(false)
                    alert(t("notifications.actions.load_configuration.error"))
                    console.error(e);
                }
            }));

            let allElement = {
                product : {
                    id: 0,
                    name: t("notifications.constants.sessions_all_products"),
                },
                allsessions: [...new Set(allS.flat())].sort()
            }

            setProductsAllSessions(result.sort((a, b) => a.product.name.localeCompare(b.product.name)))
            setProductsSelectedSessions(selected)

            setAllSessions(allElement)
            checkSelectedForAllProducts(allElement, selected, result)
            setLoading(false)
            return result
          }
          
        fetchProductSessions(products, sendDate);        
    }, [products, sendDate, apiClient, t]) 

    const checkSelectedForAllProducts = (allElement, selectedSessions, allSessions) => {
        const commonSelected = []

        if (allElement && allElement.allsessions && allElement.allsessions.length > 0) {
            allElement.allsessions.forEach((session) => {
                let includeSession = true
                for (const itemSelected of selectedSessions) {
                    let posInSelected = itemSelected.sessions.indexOf(session)
                    if (posInSelected === -1) {
                        let elementAllSessions = allSessions.find((item) => item.product.id === itemSelected.product.id)
                        if (elementAllSessions) {
                            let posInAllSessions = elementAllSessions.allsessions.indexOf(session)
                            if (posInAllSessions > -1) {
                                includeSession = false
                                break
                            }
                        }
                    } else {
                        break
                    }
                }
                if (includeSession) {
                    commonSelected.push(session)
                }
            })
        }
        setAllSelectedSessions(commonSelected)
    }

    const onSessionSelect = (productId, session, mark) => {
        let selected = [...productsSelectedSessions]
        let allProducts = [...allSelectedSessions]
        let productSelected = selected.find((elem) => elem.product.id === productId).sessions
        if (mark) {
            productSelected.push(session)
        } else {
            let productSession = productSelected.indexOf(session)
            if (productSession > -1) {
                productSelected.splice(productSession, 1)
                let unmarkAllSelectedProducts = allProducts.indexOf(session)
                if (unmarkAllSelectedProducts > -1) {
                    allProducts.splice(unmarkAllSelectedProducts, 1)
                }
            }    
        }

        setProductsSelectedSessions(selected)
        setAllSelectedSessions(allProducts)
        checkSelectedForAllProducts(allSessions, selected, productsAllSessions)
        setNotificationAllConfigureKey(prevKey => ('nc_all_' + (prevKey.replace('nc_all_', '') + 1)))
    }

    const onSelectAll = (productId, mark) => {
        let selSessions = productsSelectedSessions;
        let productAllSessions = productsAllSessions.find((element) => (element.product.id === productId))
        let productSelectedSessions = selSessions.find((element) => (element.product.id === productId))
        if (productAllSessions) {
            if (mark) {
                productSelectedSessions['sessions'] = [...productAllSessions.allsessions]
            } else {
                productSelectedSessions['sessions'] = []
            }

            setProductsSelectedSessions(selSessions)
        }
    }

    const onSessionRangeSelect = (productId, fromRange, toRange, mark) => {
        let selected = [...productsSelectedSessions]
        let allProducts = [...allSelectedSessions]
        let productSelected = selected.find((elem) => elem.product.id === productId)
        let productAllSessions = productsAllSessions.find((item) => item.product.id === productId)
        const ordererSelected = [...productSelected.sessions].sort()
        productAllSessions.allsessions.forEach((session) => {
            if (session >= fromRange && session <= toRange) {
                if (mark) {
                    let notSelected = ordererSelected.indexOf(session) === -1
                    if (notSelected) {
                        ordererSelected.push(session)
                    }
                } else {
                    let pos = ordererSelected.indexOf(session)
                    if (pos > -1) {
                        ordererSelected.splice(pos, 1)
                    }
                    let productSession = ordererSelected.indexOf(session)
                    if (productSession > -1) {
                        ordererSelected.splice(productSession, 1)
                        let unmarkAllSelectedProducts = allProducts.indexOf(session)
                        if (unmarkAllSelectedProducts > -1) {
                            allProducts.splice(unmarkAllSelectedProducts, 1)
                        }
                    }    
                }
            }
        })

        setProductsSelectedSessions(selected)
        setAllSelectedSessions(allProducts)
        checkSelectedForAllProducts(allSessions, selected, productsAllSessions)
        setNotificationAllConfigureKey(prevKey => ('nc_all_' + (prevKey.replace('nc_all_', '') + 1)))
    }

    const onAllProductsSessionSelect = (productid, session, mark) => {
        if (mark) {
            allSelectedSessions.push(session)
        } else {
            let pos = allSelectedSessions.indexOf(session)
            allSelectedSessions.slice(pos, 1)
        }
        setAllSelectedSessions(allSelectedSessions)

        let selected = []
        productsSelectedSessions.forEach((elementSelected) => {
            let productSelectedSessions = [...elementSelected.sessions]
            if (!mark) {
                let productSession = productSelectedSessions.indexOf(session)
                if (productSession > -1) {
                    productSelectedSessions.splice(productSession, 1)
                }
            } else {
                let elementProductAllSession = productsAllSessions.find((item) => item.product.id === elementSelected.product.id)
                if (elementProductAllSession) {
                    let isAvailableSession = elementProductAllSession.allsessions.indexOf(session) > -1
                    let sessionIsSelected = productSelectedSessions.indexOf(session) >-1
                    if (isAvailableSession && !sessionIsSelected ) {
                        productSelectedSessions.push(session)
                    }
                }
            }
            selected.push({
                product: elementSelected.product,
                sessions: productSelectedSessions
            })
        })

        setProductsSelectedSessions(selected)
        setNotificationConfigureKey(prevKey => ('nc_' + (prevKey.replace('nc_', '') + 1)))
    }

    const onAllProductsSessionRangeSelect = (productid, fromSession, toSession, mark) => {
        const newAllSelectedSessions = [...allSelectedSessions]
        const newProductsSelectedSessions = [...productsSelectedSessions]
        allSessions.allsessions.forEach((session) => {
            if (session >= fromSession && session <= toSession) {
                if (mark) {
                    let posInAllSessions = newAllSelectedSessions.indexOf(session)
                    if (posInAllSessions === -1) {
                        newAllSelectedSessions.push(session)
                    }
                    newProductsSelectedSessions.forEach((elementSelected) => {
                        let isSelected = elementSelected.sessions.indexOf(session) > -1
                        if (!isSelected) {
                            let elementAllSessions = productsAllSessions.find((item) => item.product.id === elementSelected.product.id)
                            let isAvailableSession = elementAllSessions.allsessions.indexOf(session) > -1
                            if (isAvailableSession) {
                                elementSelected.sessions.push(session)
                            }
                        }
                    })  
                } else {
                    let posInAllSessions = allSelectedSessions.indexOf(session)
                    if (posInAllSessions > -1) {
                        allSelectedSessions.slice(posInAllSessions, 1)
                    }

                    newProductsSelectedSessions.forEach((elementSelected) => {
                        let posInSelected = elementSelected.sessions.indexOf(session)
                        if (posInSelected) {
                            elementSelected.sessions.slice(posInSelected, 1)
                        }
                    })  
                }
            }
        })

        setAllSelectedSessions(newAllSelectedSessions)
        setProductsSelectedSessions(newProductsSelectedSessions)
        setNotificationAllConfigureKey(prevKey => ('nc_all_' + (prevKey.replace('nc_all_', '') + 1)))
        setNotificationConfigureKey(prevKey => ('nc_' + (prevKey.replace('nc_', '') + 1)))
    }

    const onAllProductsSelectAll = (productid, mark) => {
        let newAllSelectedSessions = []
        if (mark) {
            newAllSelectedSessions = [...allSessions.allsessions]
        }

        setAllSelectedSessions(newAllSelectedSessions)

        productsSelectedSessions.forEach((element) => {
            onSelectAll(element.product.id, mark)
        })

        setNotificationConfigureKey(prevKey => ('nc_' + (prevKey.replace('nc_', '') + 1)))
    }

    const actions = [
        {
            id: "save",
            title: t("notifications.actions.save_configuration.button"),
            onExecute: (data, progressHandler, onSuccess, onError) => {
                saveConfiguration()
            },
        },
        {
            id: "cancel",
            title: t("common.cancel"),
            onExecute: (data, progressHandler, onSuccess, onError) => {
                navigate(availableRoutes.notifications_view.path.replace(":entityId", entityId))
            },
        },
    ]

    const saveConfiguration = () => {

        const notificationData = {
        }

        let times = []
        productsSelectedSessions.forEach((element) => {
            if (!_isEmpty(element.sessions)) {
                let elementSessions = []
                element.sessions.forEach((time) => {
                    elementSessions.push(time)
                })
                times.push({
                    id: element.product.id,
                    sessions: elementSessions
                })
            }
        })

        if (!_isEmpty(times)) {
            _set(notificationData, "products", times)
        }

        apiClient.notifications.updateProductsNotification(entityId, notificationData, 'application')
            .then((notification) => {
                navigate(availableRoutes.notifications_view.path.replace(":entityId", notification.id))
            })
            .catch((error) => {
                addAlert("Error: " + error.message)
            })
    }

    return (
        <Grid container>
            <Grid item xs={12} sx={{
                bgcolor: "common.white",
                padding: 2,
                paddingLeft: 4,
                paddingBlockEnd: 0,
                boxShadow: `inset 0 -0.0625rem ${theme.palette.common.container.border}, 0 0.125rem 0.25rem 0 rgb(85 107 30 / 8%)`,
                //...stickyHeaderConfig
            }}>
                <Box
                    display="flex"
                    pb={1}
                >
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="center"
                        flexDirection="column"
                        flexGrow={1}
                    >
                        <Title level="h2">{t("notifications.actions.configure_sessions.title")}</Title>
                        <Title level="subtitle">{subtitle}</Title>
                    </Box>
                    <EntityActionsGroup actions={actions} />
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ padding: { xs: 2, md: 4 }, paddingTop: 2 }}>
                <Box sx={{ bgcolor: "common.white", padding: 2, borderRadius: 2 }}>
                    <SimpleLoading loading={loading} />
                    <Stack spacing={2}>
                        {!loading && 
                            !_isEmpty(allSessions) && 
                            (allSessions.allsessions) && 
                            (allSessions.allsessions.length > 0) && (

                                <ConfigureSessionsProduct
                                    key={notificationAllConfigureKey}
                                    productAllSessions={allSessions}
                                    productSelectedSessions={allSelectedSessions}
                                    t={t}
                                    onSessionSelect={onAllProductsSessionSelect}
                                    onSelectAll={onAllProductsSelectAll}
                                    onSessionRangeSelect={onAllProductsSessionRangeSelect}
                                />
                            )
                        }
                        {!loading && (!_isEmpty(productsAllSessions)) && (
                            <ConfigureNotificationSessions 
                                key={notificationConfigureKey}
                                productsAllSessions={productsAllSessions}
                                productsSelectedSessions={productsSelectedSessions}
                                t={t}
                                onSessionSelect={onSessionSelect}
                                onSelectAll={onSelectAll}
                                onSessionRangeSelect={onSessionRangeSelect}
                            />
                        )}
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    )
}


export default NotificationSessionsConfiguration;