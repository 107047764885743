import constants from "../../../components/entity/constants"
import { createAggField, createAsyncSelectFormField, createBooleanFormField, createDependentFormField, createDialogFormField, createField, createFormField, createFormFieldOption } from "../../../components/helpers/fieldHelper"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import { buildOptions, paymentModesFetcher } from '../../../lib/form-options-fetchers';
import { getActivitiesFilter, getIntermediariesFilter, getDiscountCodesFilter } from "../../../lib/collection-filters";
import userPermissions from "../../../lib/user-permissions";

export const getTableFilter = (apiClient, t, user, cache) => ([
    createAggField(
        "agg_fields",
        "",
        [
            {
                id: "total_pax",
                label: "bookings.aggregate.total_pax",
            }, {
                id: "total_retail_price",
                label: "bookings.aggregate.total_retail_price",
                type: constants.CURRENCY_TYPE
            }, {
                id: "total_net_price",
                label: "bookings.aggregate.total_net_price",
                type: constants.CURRENCY_TYPE,
                condition: () => user.hasPermission(userPermissions.PERM_BOOKINGS_VIEW_NET_PRICE)
            }
        ],
    ),
    createField(
        "locator",
        t("bookings.fields.locator"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createField(
        "experience_name",
        t("bookings.fields.experience"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createField(
        "product_name",
        t("bookings.fields.product"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createField(
        "customer",
        t("bookings.filter.customer_name"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createField(
        "order_date_from",
        t("bookings.filter.order_date_from"),
        constants.DATE_TYPE
    ),
    createField(
        "order_date_to",
        t("bookings.filter.order_date_to"),
        constants.DATE_TYPE
    ),
    createField(
        "booking_date_from",
        t("bookings.filter.booking_date_from"),
        constants.DATE_TYPE,
        null,
        null,
        true
    ),
    createField(
        "booking_date_to",
        t("bookings.filter.booking_date_to"),
        constants.DATE_TYPE,
        null,
        null,
        true
    ),
    getIntermediariesFilter(apiClient, t, user, 'collaborator_id', false),
    createDialogFormField(
        "supplier_id",
        t("bookings.filter.supplier"),
        false,
        {
            filterConfig: [
                createFormField(
                    createField(
                        "name",
                        t("bookings.filter.name"),
                        constants.STRING_TYPE
                    ),
                    false
                ),
            ],
            columns: [
                createField(
                    "name",
                    t("bookings.filter.supplier"),
                    constants.STRING_TYPE
                )
            ],
            fetcher: (params) => apiClient.catalog.supplier.getSuppliers({ ...params })
        },
        (value) => ({
            id: value.id,
            label: value.name,
            entity: value
        }),
        () => user.hasPermission('view-any-supplier')
    ),
    /*createSelectFormField(
        "state",
        t("bookings.filter.state"),
        [createFormFieldOption("", t("table.all"))].concat(
            Object.entries(createBookingStateMapping(t)).map(([value, label]) => ({ value: value, label: label }))
        )
    ),*/
    createBooleanFormField(
        t,
        "confirmed",
        t("bookings.filter.confirmed")
    ),
    createFormField(
        createAsyncSelectFormField(
            "payment_method_id",
            t("bookings.filter.payment_method"),
            () => {
                return paymentModesFetcher(cache, apiClient, t).then((result) => {
                    return result.filter((item) => item.label !== t("bookings.constants.payment_method.paygold") && item.label !== t("bookings.constants.payment_method.oneclick"))
                })
            },
            true,
            () => !user.isIntermediary() && user.hasPermission(userPermissions.PERM_ENTERPRISES_PAYMENT_GATEWAYS_VIEW),
            null,
            false,
            true
        ),
        false,
        []
    ),
    createField(
        "transaction_id",
        t("bookings.filter.transaction_id"),
        constants.STRING_TYPE,
        null,
        null,
        true
    ),
    createBooleanFormField(
        t,
        "valid",
        t("bookings.filter.valid"),
        null,
        true,
        1
    ),
    createBooleanFormField(
        t,
        "cancelled",
        t("bookings.filter.cancelled")
    ),
    createBooleanFormField(
        t,
        "validated",
        t("bookings.filter.validated")
    ),
    createAsyncSelectFormField(
        "currency",
        t("bookings.filter.currencies"),
        () => {
            const enterpriseId = parseInt(_get(user, "corporate_account.enterprise.id", "-1"))
            let result = [
                createFormFieldOption("", t("table.all")),
                createFormFieldOption("main", t("currencies.fields.main")),
                createFormFieldOption("others", t("currencies.fields.others"))
            ]

            return cache.get("enterprises", []).then((enterprises) => {
                
                if (_isEmpty(enterprises)) {
                    return result
                }
                
                const enterprise = enterprises.find((enterprise) => enterprise.id === enterpriseId)
                if (enterprise.currencies) {
                    result = [...result, ...buildOptions(enterprise.currencies.available, t, "id", "id", false)]
                }
                
                return result
            })
        },
        false,
        () => user.corporate_account.enterprise.id === 1 // Filter available only for Volcano
    ),
    createFormField(
        createAsyncSelectFormField(
            "office_id",
            t("bookings.filter.office"),
            () => {
                const options = [createFormFieldOption("", t("table.all"))]
                return cache.get("offices", []).then((items) => {
                    return options.concat(items.map((item) => ({ value: item.collaborator_office_id, label: item.name })))
                })
            },
            false,
            () => user.isIntermediary() && user.hasPermission(userPermissions.PERM_CRM_INTERMEDIARY_OFFICE_VIEW)
        ),
        false,
        user.hasOwnProperty('crm_intermediary_office') ? user.crm_intermediary_office.collaborator_office_id : null,
    ),
    createAsyncSelectFormField(
        "salesman_id",
        t("bookings.filter.salesman"),
        () => {
            const options = [createFormFieldOption("", t("table.all"))]
            return cache.get("salesmen", []).then((items) => {
                return options.concat(items.map((item) => ({ value: item.id, label: item.name })))
            })
        },
        false,
        () => cache.has("salesmen")
    ),
    getDiscountCodesFilter(apiClient, t, user),
    getActivitiesFilter(apiClient, t, user),
    createField(
        "notes",
        t("bookings.fields.order.customer.notes"),
        constants.STRING_TYPE,
        null,
        () => !user.isIntermediary()
    ),
    createDependentFormField(
        "activity_pickup_id",
        t("bookings.filter.activity_pickup"),
        constants.SELECT_TYPE,
        (values) => {
            const activityPickups = _get(values, "activity_id[0].entity.activity_pickups", [])

            if (activityPickups.length === 0) {
                return []
            }

            const options = [createFormFieldOption("", t("table.all"))]
            return options.concat(activityPickups.map((item) => ({ value: item.id, label: item.name })))
        },
        () => user.hasPermission('view-any-activity')
    )
])