import { useTranslation } from "react-i18next"
import constants from "../../../../components/entity/constants"
import { useVolcanoApiClient } from "../../../../context/VolcanoApiClientProvider"
import {
    createEditableListFormField,
    createCallbackField, createFormFieldset, createFormField, createField
} from "../../../../components/helpers/fieldHelper"
import AsyncEntityFormPage from "../../../../layout/templates/AsyncContentPage/AsyncEntityFormPage"
import {useNavigate, useParams} from "react-router-dom"
import availableRoutes from "../../../../routes/availableRoutes"
import ValueSelectorDialog from "../../../../components/ValueSelectorDialog/ValueSelectorDialog";
import {getProductsFilter} from "../../../../lib/collection-filters";
import {useCache} from "../../../../context/cache.context";


const ActivityConfigProductsPage = () => {
    const { apiClient } = useVolcanoApiClient()
    const { t } = useTranslation("vbms")
    const navigate = useNavigate()
    const { entityId } = useParams()
    const cache = useCache()

    const fetcher = () => {
        return apiClient.activityManager.activityConfig
            .getActivityConfig(entityId, [])
            .then((route) => {
                return {
                    ...route
                }
            })
    }

    const productsFilterField = getProductsFilter(apiClient, t, true, cache)


    const handleClose = () => {
        navigate(
            availableRoutes.activity_config_view.path.replace(":entityId", entityId)
        )
    }

    const toBoolean = (value) => {
        if (typeof value === 'string') {
            return value.toLowerCase() === 'true';
        }
        return !!value;
    };

    const onConfirm = (values) => {

        const activityConfigUpdate = {
            products: values.products.map((product) => {
                return {
                    id: product.id,
                    with_resources: toBoolean(product.with_resources) ? 1 : 0,
                    allow_multiple_activities: toBoolean(product.allow_multiple_activities) ? 1 : 0,
                    ignore_sessions: toBoolean(product.ignore_sessions) ? 1 : 0
                }
            })
        }

        return apiClient.activityManager.activityConfig
            .updateProducts(entityId, activityConfigUpdate)
            .then(() => {
                navigate(
                    availableRoutes.activity_config_view.path.replace(":entityId", entityId)
                )
            })
    }

    const formContent = [
        createFormFieldset("", [
            createEditableListFormField(
                "products",
                "",
                "name",
                t("routes.actions.products.modal_title"),
                ({ onConfirm, onCancel }) => <ValueSelectorDialog
                    table={productsFilterField.table}
                    selected={productsFilterField.selected}
                    valueFormatter={productsFilterField.valueFormatter}
                    multipleSelection={true}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                />,
                true,
                true,
                true,
                null,
                [
                    createFormField(
                        createField(
                            "with_resources",
                            t("activity_configs.fields.resources"),
                            constants.BOOLEAN_TYPE
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createField(
                            "allow_multiple_activities",
                            t("activity_configs.fields.allow_multiple_activities"),
                            constants.BOOLEAN_TYPE
                        ),
                        true,
                        ""
                    ),
                    createFormField(
                        createField(
                            "ignore_sessions",
                            t("activity_configs.fields.ignore_sessions"),
                            constants.BOOLEAN_TYPE
                        ),
                        true,
                        ""
                    )
                ]
            ),
        ])
    ]

    return (
        <AsyncEntityFormPage
            fetcher={fetcher}
            title={createCallbackField(
                "name",
                t("activity_configs.fields.name"),
                constants.STRING_TYPE,
                (value) => {
                    return t("activity_configs.view.title", { name: value })
                }
            )}
            variant="extended"
            onSave={onConfirm}
            onClose={handleClose}
            formContent={formContent}
        />
    );
};

export default ActivityConfigProductsPage
