import { Field } from "formik"
import {TimePicker} from "@mui/x-date-pickers"

const TimePickerField = ({ field, form, ...props }) => {

    const onChange = (value) => {
        if (value.toString() !== "Invalid Date") {
            form.setFieldValue(field.name, value ? value.toISOString()
                .split("T")[1]
                .split(".")[0] : null)
        }
    }

    function hoursToDate(timeString) {
        if (!timeString) return null

        const date = new Date(0)
        const [hours, minutes] = timeString.split(":").map(Number)

        return date.setHours(hours, minutes, 0, 0)
    }

    return (
        <TimePicker
            format="HH:mm"
            views={['hours', 'minutes']}
            label={props.label}
            value={hoursToDate(field.value)}
            slotProps={{ textField: { size: "small", InputLabelProps: { shrink: true }, fullWidth: true } }}
            ampm={false}
            readOnly={false}
            disableOpenPicker={false}
            onChange={(value) => onChange(value)}
        />
    )
}

const TimeFormField = ({ field, value }) => {

    return (
        <Field
            component={TimePickerField}
            name={field.id}
            label={field.label}
            required={field.required}
        />
    )
}

export default TimeFormField
