import { createContext, useCallback, useContext, useEffect, useReducer, useRef } from "react"
import Loading from "../../../Display/Loading"
import useAsync from "../../../hooks/useAsync"
import useSnackBars from "../../../Snackbar/snack-bar.context"
import { setPage, setResult } from "./collection.action"
import dataTableReducer from "./collection.reducer"
import { useSearchParams } from "react-router-dom"
import _isDate from "lodash/isDate"
import _get from "lodash/get"
import _set from "lodash/set"
import { format as formatDate } from "date-fns"
import constants from "../../constants"
import { useFilterContext } from "../filter/filter.context"


const Context = createContext([{}, () => { }])

export const initialTableState = {
    dirty: false,
    rowsPerPage: 20,
    page: 0,
    orderBy: false,
    order: "asc",
    filter: {},
    result: {
        total: 0,
        count: 0,
        pageCount: 0,
        actions: [],
        rows: [],
    },
};

const processInitialTableState = (filter, rowsPerPage, searchParams) => {

    const defaultState = {
        ...initialTableState,
        rowsPerPage: rowsPerPage,
    }

    if (searchParams.has("q") && !filter.ignoreSearchParams) {
        const params = JSON.parse(atob(searchParams.get("q")))

        // initialize pagination params
        defaultState.page = _get(params, "page", initialTableState.page)
        defaultState.rowsPerPage = _get(params, "rowsPerPage", initialTableState.rowsPerPage)
        defaultState.orderBy = _get(params, "orderBy", false)
        defaultState.order = _get(params, "order", "asc")

        // todo: initialize order params
    }

    return defaultState
}

const processQueryParams = (filterConfig, filter) => {
    const result = {
        ...filter
    }

    // process date filter params
    if (filterConfig) {
        filterConfig
            .filter(field => field.type === constants.DATE_TYPE)
            .forEach(field => {
                const date = _get(filter, field.id)
                if (_isDate(date)) {
                    _set(result, field.id, formatDate(date, "yyyy-MM-dd"))
                }
            })
    }

    return result
}

const ArrayDataTableProvider = (props) => {
    const [table, tableDispatch] = useReducer(
        dataTableReducer,
        {
            ...initialTableState,
            rowsPerPage: props.data.length,
        }
    )

    const fetcher = useCallback(
        () => {
            tableDispatch(
                setResult({
                    total: props.data.length,
                    count: props.data.length,
                    pageCount: 1,
                    rows: props.data,
                    actions: [],
                })
            )
        }, [props.data])

    useEffect(
        () => fetcher(),
        [table.page, table.orderBy, table.order, table.rowsPerPage, table.filter, fetcher]
    )

    return (
        <Context.Provider value={[table, tableDispatch]}>
            {props.children}
        </Context.Provider>
    )
}

const CollectionProvider = (props) => {
    const { execute, status, value, error } = useAsync((params) => {
        let queryParams = {
            limit: params.rowsPerPage,
            page: params.page + 1,
            ...params.filter,
        };

        if (params.orderBy) {
            queryParams = {
                ...queryParams,
                sort: params.orderBy,
                sort_direction: params.order
            }
        }

        return props.fetcher(queryParams)
    }, false)

    const [filterConfig, filter, ,] = useFilterContext()

    const [searchParams,] = useSearchParams()

    const [table, tableDispatch] = useReducer(
        dataTableReducer,
        processInitialTableState(filter, props.rowsPerPage, searchParams)
    )

    const { addAlert } = useSnackBars()

    const filterChangeRef = useRef(filter)

    const refresh = useCallback(() => {
        const collectionFilter = processQueryParams(filterConfig, filter)

        execute({
            page: table.page,
            rowsPerPage: table.rowsPerPage,
            filter: collectionFilter,
            order: table.order,
            orderBy: table.orderBy,
        })
    }, [filterConfig, filter, table.page, table.orderBy, table.order, table.rowsPerPage, table.dirty])

    const processAggregateFields = useCallback((value) => {
        if (!filterConfig) {
            return []
        }
        const collectionAggFields = value.getAggregateFields()
        const filterAggConfig = filterConfig.filter((item) => {
            return item.type === "aggregate"
        })

        if (collectionAggFields.length === 0 || filterAggConfig.length === 0) {
            return []
        }

        const result = []
        filterAggConfig[0].options.forEach((aggConfig) => {
            const matchField = Object.entries(collectionAggFields).find((item) => {
                return item[0] === aggConfig.id
            })

            if (matchField) {
                result.push({
                    id: aggConfig.id,
                    label: aggConfig.label,
                    type: aggConfig.type,
                    value: matchField[1],
                })
            }
        })

        return result
    }, [filterConfig])

    useEffect(() => {
        if (filterChangeRef.current !== filter && table.page !== 0) {
            filterChangeRef.current = filter
            tableDispatch(setPage(initialTableState.page))
            return
        }

        const collectionFilter = processQueryParams(filterConfig, filter)

        execute({
            page: table.page,
            rowsPerPage: table.rowsPerPage,
            filter: collectionFilter,
            order: table.order,
            orderBy: table.orderBy,
        })
    }, [filterConfig, filter, table.page, table.orderBy, table.order, table.rowsPerPage])

    useEffect(() => {
        if (!table.dirty) {
            return
        }

        const collectionFilter = processQueryParams(filterConfig, filter)

        execute({
            page: table.page,
            rowsPerPage: table.rowsPerPage,
            filter: collectionFilter,
            order: table.order,
            orderBy: table.orderBy,
        })
    }, [filterConfig, filter, table.page, table.orderBy, table.order, table.rowsPerPage, table.dirty])

    useEffect(() => {
        console.log("Async call status: " + status)
        switch (status) {
            case "error":
                addAlert(`API error [${error.type}]: ${error.message}`, "error")
                break
            case "success":
                tableDispatch(
                    setResult({
                        total: value.getTotal(),
                        count: value.getCount(),
                        pageCount: value.getPageCount(),
                        rows: value.getItems(),
                        actions: value.getActions(),
                        aggregateFields: processAggregateFields(value),
                    })
                )
                break
            default:
                break
        }
    }, [status, value, error, addAlert, processAggregateFields])

    const loading = status === "pending"

    return (
        <Context.Provider value={[table, tableDispatch]}>
            {<Loading open={loading} />}
            {props.children}
        </Context.Provider>
    )
}

const useDataTableContext = () => useContext(Context)

export {
    ArrayDataTableProvider,
    CollectionProvider,
    useDataTableContext
}
