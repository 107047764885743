import constants from '../../../components/entity/constants';
import { createField, createDataSetFacet, createCallbackField } from '../../../components/helpers/fieldHelper';
import _isEmpty from 'lodash/isEmpty';


export const getHeaderContent = (t, user) => [
    createDataSetFacet(t("venues.view.facet_information"), [
        createField(
            "id",
            "Id",
            constants.STRING_TYPE
        ),
        createField(
            "key",
            t("venues.fields.key"),
            constants.STRING_TYPE,
            null
        ),
        createField(
            "enterprise.name",
            t("venues.fields.enterprise"),
            constants.STRING_TYPE,
            null,
            () => user.hasPermission("access-any-enterprise")
        ),
        createCallbackField(
            "sites",
            t("venues.fields.sites"),
            constants.STRING_TYPE,
            (values) => _isEmpty(values) ? t("venues.constants.no_site") : values.map((value) => value.name).join(', ')
        ),
        createField(
            "created",
            t("common.fields.created"),
            constants.DATETIME_TYPE
        )
    ])
]